<template>
  <main class="kb-main" id="kb-facility">
    <lxp-main-header :show-back="true" :show-title="false" :show-total="true">
      <template v-slot:pre>
        <!-- main-header -->
        <div class="main-header">
          <div class="facility-top">
            <div class="facility-top-inner min-component">
              <div class="facility-image">
                <div class="image"><img src="../../../assets/lxp/images/@tmp/@tmp_facility_01.jpg" alt="임시이미지" /></div>
              </div>
              <div class="facility-contents">
                <div class="content-title">
                  <h3 class="title">속초 연수원</h3>
                </div>
                <div class="content-meta">
                  <div class="divider">
                    <span class="text">2022.12.09 - 2022.01.16</span>
                    <span class="text">3박4일</span>
                  </div>
                  <div class="divider">
                    <span class="text">성인 2</span>
                  </div>
                </div>
              </div>
              <div class="facility-actions">
                <a href="#" class="kb-btn kb-btn-white"><span class="text">재검색</span></a>
              </div>
            </div>
          </div>
        </div>
        <!-- main-header > header-top -->
      </template>
    </lxp-main-header>

    <!-- main-content -->
    <div class="main-content min-component">
      <!-- facility-list-container -->
      <div class="facility-list-container">
        <ul class="facility-list">
          <!-- facility-item -->
          <li class="facility-item">
            <div class="facility-card">
              <div class="facility-image">
                <a href="javascript:" class="image-link"></a>
                <div class="image">
                  <img src="../../../assets/lxp/images/@tmp/@tmp_facility_list_01.jpg" alt="임시이미지" />
                </div>
              </div>
              <div class="facility-content">
                <div class="content-title">
                  <h4 class="title">속초 연수원 203동 1201호</h4>
                </div>
                <div class="content-meta">
                  <span class="text">18평</span>
                  <span class="text">고층</span>
                  <span class="text">퀸</span>
                </div>
                <div class="content-description">
                  <p class="text">2019년 6월 신축건물로 깔끔한외관과 깨끗한 내부로 매일 이루어지는 청소와 소모품교체로 항상청결하며 깔끔한 룸 컨디신축건물로 깔끔한외관과 깨끗한 내부로 매일 이루어지는 청소와 소모품교체로 항상청결하며 깔끔한 룸 컨디</p>
                </div>
              </div>
              <div class="facility-actions">
                <button class="kb-btn kb-btn-outline rounded-lg"><span class="text">상세</span></button>
                <a href="#" class="kb-btn kb-btn-outline rounded-lg"><span class="text">예약</span></a>
              </div>
            </div>
          </li>
          <!-- facility-item -->
          <li class="facility-item">
            <div class="facility-card">
              <div class="facility-image">
                <a href="javascript:" class="image-link"></a>
                <div class="image">
                  <img src="../../../assets/lxp/images/@tmp/@tmp_facility_list_02.jpg" alt="임시이미지" />
                </div>
              </div>
              <div class="facility-content">
                <div class="content-title">
                  <h4 class="title">속초 연수원 203동 1201호</h4>
                </div>
                <div class="content-meta">
                  <span class="text">18평</span>
                  <span class="text">고층</span>
                  <span class="text">퀸</span>
                </div>
                <div class="content-description">
                  <p class="text">2019년 6월 신축건물로 깔끔한외관과 깨끗한 내부로 매일 이루어지는 청소와 소모품교체로 항상청결하며 깔끔한</p>
                </div>
              </div>
              <div class="facility-actions">
                <button class="kb-btn kb-btn-outline rounded-lg"><span class="text">상세</span></button>
                <a href="#" class="kb-btn kb-btn-outline rounded-lg"><span class="text">예약</span></a>
              </div>
            </div>
          </li>
          <!-- facility-item -->
          <li class="facility-item">
            <div class="facility-card">
              <div class="facility-image">
                <a href="javascript:" class="image-link"></a>
                <div class="image">
                  <img src="../../../assets/lxp/images/@tmp/@tmp_facility_list_03.jpg" alt="임시이미지" />
                </div>
              </div>
              <div class="facility-content">
                <div class="content-title">
                  <h4 class="title">속초 연수원 203동 1201호</h4>
                </div>
                <div class="content-meta">
                  <span class="text">18평</span>
                  <span class="text">고층</span>
                  <span class="text">퀸</span>
                </div>
                <div class="content-description">
                  <p class="text">2019년 6월 신축건물로 깔끔한외관과 깨끗한 내부로 매일 이루어지는 청소와 소모품교체로 항상청결하며 깔끔한 룸 컨디신축건물로 깔끔한외관과 깨끗한 내부로 매일 이루어지는 청소와 소모품교체로 항상청결하며 깔끔한 룸 컨디</p>
                </div>
              </div>
              <div class="facility-actions">
                <button class="kb-btn kb-btn-outline rounded-lg"><span class="text">상세</span></button>
                <a href="#" class="kb-btn kb-btn-outline rounded-lg"><span class="text">예약</span></a>
              </div>
            </div>
          </li>
          <!-- facility-item -->
          <li class="facility-item">
            <div class="facility-card">
              <div class="facility-image">
                <a href="javascript:" class="image-link"></a>
                <div class="image">
                  <img src="../../../assets/lxp/images/@tmp/@tmp_facility_list_04.jpg" alt="임시이미지" />
                </div>
              </div>
              <div class="facility-content">
                <div class="content-title">
                  <h4 class="title">속초 연수원 203동 1201호</h4>
                </div>
                <div class="content-meta">
                  <span class="text">18평</span>
                  <span class="text">고층</span>
                  <span class="text">퀸</span>
                </div>
                <div class="content-description">
                  <p class="text">2019년 6월 신축건물로 깔끔한외관과 깨끗한 내부로 매일 이루어지는 청소와 소모품교체로 항상청결하며 깔끔한 룸 컨디신축건물로 깔끔한외관과 깨끗한 내부로 매일 이루어지는 청소와 소모품교체로 항상청결하며 깔끔한 룸 컨디</p>
                </div>
              </div>
              <div class="facility-actions">
                <button class="kb-btn kb-btn-outline rounded-lg"><span class="text">상세</span></button>
                <a href="#" class="kb-btn kb-btn-outline rounded-lg"><span class="text">예약</span></a>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!-- //facility-list-container -->
      <!-- facility-sticky-filter -->
      <div class="facility-sticky-filter">
        <div class="dropdown">
          <button class="kb-btn kb-btn-primary kb-btn-lg dropdown-btn"><span class="text">필터</span></button>
          <div class="dropdown-target">
            <div class="dropdown-box">
              <form @submit.prevent>
                <!-- filter-group:평형 -->
                <div class="filter-section">
                  <h5 class="section-title">평형</h5>
                  <div class="filter-options">
                    <div class="kb-form-check">
                      <input type="radio" class="kb-form-check-input" name="chk_01" id="chk_01_1" checked />
                      <label for="chk_01_1" class="kb-form-check-label">전체</label>
                    </div>
                    <div class="kb-form-check">
                      <input type="radio" class="kb-form-check-input" name="chk_01" id="chk_01_2" />
                      <label for="chk_01_2" class="kb-form-check-label">18평 (2-4인용)</label>
                    </div>
                    <div class="kb-form-check">
                      <input type="radio" class="kb-form-check-input" name="chk_01" id="chk_01_3" />
                      <label for="chk_01_3" class="kb-form-check-label">32평 (2-6인용)</label>
                    </div>
                    <div class="kb-form-check">
                      <input type="radio" class="kb-form-check-input" name="chk_01" id="chk_01_4" />
                      <label for="chk_01_4" class="kb-form-check-label">58평 (4-10인용)</label>
                    </div>
                  </div>
                </div>
                <!-- filter-group:층수 -->
                <div class="filter-section">
                  <h5 class="section-title">층수</h5>
                  <div class="filter-options">
                    <div class="kb-form-check">
                      <input type="radio" class="kb-form-check-input" name="chk_02" id="chk_02_1" checked />
                      <label for="chk_02_1" class="kb-form-check-label">전체</label>
                    </div>
                    <div class="kb-form-check">
                      <input type="radio" class="kb-form-check-input" name="chk_02" id="chk_02_2" />
                      <label for="chk_02_2" class="kb-form-check-label">고층</label>
                    </div>
                    <div class="kb-form-check">
                      <input type="radio" class="kb-form-check-input" name="chk_02" id="chk_02_3" />
                      <label for="chk_02_3" class="kb-form-check-label">저층</label>
                    </div>
                  </div>
                </div>
                <!-- filter-group:침대 -->
                <div class="filter-section">
                  <h5 class="section-title">침대</h5>
                  <div class="filter-options">
                    <div class="kb-form-check">
                      <input type="radio" class="kb-form-check-input" name="chk_03" id="chk_03_1" checked />
                      <label for="chk_03_1" class="kb-form-check-label">슈퍼싱글 X 2</label>
                    </div>
                    <div class="kb-form-check">
                      <input type="radio" class="kb-form-check-input" name="chk_03" id="chk_03_2" />
                      <label for="chk_03_2" class="kb-form-check-label">퀸 X 2</label>
                    </div>
                    <div class="kb-form-check">
                      <input type="radio" class="kb-form-check-input" name="chk_03" id="chk_03_3" />
                      <label for="chk_03_3" class="kb-form-check-label">온돌</label>
                    </div>
                  </div>
                </div>
                <div class="filter-buttons">
                  <button class="kb-btn kb-btn-primary kb-btn-wide" disabled>
                    <span class="text">적용</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- //facility-sticky-filter -->
    </div>
    <!-- //main-content -->
  </main>
</template>

<script>
import LxpMainHeader from '@/components/common/LxpMainHeader';
export default {
  name: 'DailyHistory',
  components: {LxpMainHeader},
};
</script>

<style scoped>

</style>